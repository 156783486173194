import * as React from "react";
import Modal from "react-modal";
import { Products } from "../object/products";
import * as styles from "../styles/sass/layout/_aboutBreed.module.scss";

Modal.setAppElement("#___gatsby");

const ProductList = () => {
  const [modalOpen, setModalOPen] = React.useState("");

  return (
    <ul className={styles.aboutBreed__list}>
      {Products.map((product) => {
        return (
          <>
            <li key={product.id} className={styles.aboutBreed__item}>
              <picture>
                <source srcset={product.spImg} media="(max-width: 650px)" />
                <img src={product.img} alt="商品画像" />
              </picture>
              <div className={styles.aboutBreed__desc}>
                <h3>{product.name}</h3>
                <p>{product.text}</p>
                <button onClick={() => setModalOPen(product.name)}>
                  価格表
                </button>
              </div>
            </li>
            <Modal
              isOpen={product.name == modalOpen}
              onRequestClose={() => setModalOPen(false)}
              overlayClassName={{
                base: "overlay__base",
                afterOpen: "overlay__after",
                beforeClose: "overlay__before",
              }}
              className={{
                base: "content__base",
                afterOpen: "content__after",
                beforeClose: "content__before",
              }}
              closeTimeoutMS={600}
            >
              <div className="content__wrapper">
                <div onClick={() => setModalOPen(false)}>
                  <span></span>
                </div>
                <h4 className="content__title">{product.name}</h4>
                <table className="content__price">
                  <thead>
                    <tr>
                      <th>家庭用</th>
                      <th>贈答用</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span>{product.prices.household[0].size}</span>
                        <p>{product.prices.household[0].price}</p>
                      </td>
                      <td>
                        <span>{product.prices.gift[0].size}</span>
                        <p>{product.prices.gift[0].price}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{product.prices.household[1].size}</span>
                        <p>{product.prices.household[1].price}</p>
                      </td>
                      <td>
                        <span>{product.prices.gift[1].size}</span>
                        <p>{product.prices.gift[1].price}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {product.name == "まどんな" || product.name == "せとか" ? (
                  <p
                    style={{
                      textAlign: "center",
                      lineHeight: "1.5",
                      marginBottom: "6%",
                    }}
                  >
                    <b>※全て税込、全国送料込、家庭用サイズ指定不可。<br />詳細はお問い合わせください。</b>
                  </p>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      lineHeight: "1.5",
                      marginBottom: "6%",
                    }}
                  >
                    <b>※全て税込、全国送料込、サイズ指定はできません。<br />詳細はお問い合わせください。</b>
                  </p>
                )}
                <a href={product.url}>購入はこちらから</a>
              </div>
            </Modal>
          </>
        );
      })}
    </ul>
  );
};

export default ProductList;

import * as React from "react";
import AboutBreed from "../components/aboutBreed";
import AboutInstagram from "../components/aboutInstagram";
import Footer from "../components/footer";
import Header from "../components/header";
import PageHeader from "../components/pageHeader";

const Breed = () => {
  return (
    <React.Fragment>
      <Header />
      <main>
        <PageHeader page="breed">BREED</PageHeader>
        <AboutBreed />
        <AboutInstagram />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Breed;

export const Head = () => <title>GoGo Island | BREED</title>
import productDemo from "../images/top1.jpg";
import madonna from "../images/madonna.jpg"
import madonnaSp from "../images/madonna-sp.png"
import mikan from "../images/mikan.png";

export const Products = [
  {
    id: 1,
    name: "まどんな",
    text: "果汁が豊富で甘みが強く、濃厚な風味と上品な香りが特徴。",
    img: madonna,
    spImg: madonnaSp,
    prices: {
      household: [
        {
          id: 1,
          size: "2.5kg以上(サイズ指定不可)",
          price: "4,900円",
        },
        {
          id: 2,
          size: "5kg以上(サイズ指定不可)",
          price: "6,900円",
        },
      ],
      gift: [
        {
          id: 1,
          size: "1.5kg以上(Lサイズ以上 5~8個)",
          price: "5,400円",
        },
        {
          id: 2,
          size: "2.5kg以上(Lサイズ以上 9~12個)",
          price: "7,000円",
        },
      ],
    },
    url: "https://www.gogo-island-3.com/",
  },
  {
    id: 2,
    name: "せとか",
    text: "とても甘みが強く、香りや食味のよい柑橘を掛け合わせたいい所どりの柑橘です。",
    img: productDemo,
    spImg: mikan,
    prices: {
      household: [
        {
          id: 1,
          size: "2.5kg以上(サイズ指定不可)",
          price: "4,900円",
        },
        {
          id: 2,
          size: "5kg以上(サイズ指定不可)",
          price: "6,900円",
        },
      ],
      gift: [
        {
          id: 1,
          size: "1.5kg以上(Lサイズ以上 5~8個)",
          price: "5,400円",
        },
        {
          id: 2,
          size: "2.5kg以上(Lサイズ以上 9~12個)",
          price: "7,000円",
        },
      ],
    },
    url: "https://www.gogo-island-3.com/",
  },
  {
    id: 3,
    name: "宮内いよかん",
    text: "甘みが強く、酸味は控えめです。果肉はとてもやわらかく、ジューシーです。",
    img: productDemo,
    spImg: mikan,
    prices: {
      household: [
        {
          id: 1,
          size: "約4kg(サイズ指定不可)",
          price: "4,300円",
        },
        {
          id: 2,
          size: "約9kg(サイズ指定不可)",
          price: "6,900円",
        },
      ],
      gift: [
        {
          id: 1,
          size: "約4kg(サイズ指定不可)",
          price: "5,400円",
        },
        {
          id: 2,
          size: "約9kg(サイズ指定不可)",
          price: "9,000円",
        },
      ],
    },
    url: "https://www.gogo-island-3.com/",
  },
  {
    id: 4,
    name: "カラマンダリン",
    text: "濃厚な甘みと、それを支えるしっかりとした酸が感じられます。",
    img: productDemo,
    spImg: mikan,
    prices: {
      household: [
        {
          id: 1,
          size: "3kg以上(サイズ指定不可)",
          price: "3,800円",
        },
        {
          id: 2,
          size: "-",
          price: "-",
        },
      ],
      gift: [
        {
          id: 1,
          size: "3kg以上(サイズ指定不可)",
          price: "4,900円",
        },
        {
          id: 2,
          size: "-",
          price: "-",
        },
      ],
    },
    url: "https://www.gogo-island-3.com/",
  },
];

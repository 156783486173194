import * as React from "react";
import * as styles from "../styles/sass/layout/_aboutBreed.module.scss";
import ProductList from "./productList";

const AboutBreed = () => {
  return (
    <div className={styles.aboutBreed}>
      <h2>GoGoIsrand商品</h2>
      <ProductList />
    </div>
  );
};

export default AboutBreed;
